import {
  RECOMMENDATION_BY_ID_SUCCESS,
  RECOMMENDATION_ERROR,
  RECOMMENDATION_REQUEST,
  RECOMMENDATION_SUCCESS,
} from "src/constant/Types";
import { RecommendationState } from "./Modules/recommendation";

const initialState: RecommendationState = {
  loader: false,
  recommendation: [],
  recommendationById: {},
  metaData: {},
  totalPage: 0,
  totalCount: 0,
};

const RecommendationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RECOMMENDATION_REQUEST: {
      return Object.assign({}, state, {
        loader: true,
      });
    }
    case RECOMMENDATION_BY_ID_SUCCESS: {
      return Object.assign({}, state, {
        recommendationById: action.payload,
        loader: false,
      });
    }

    case RECOMMENDATION_SUCCESS: {
      return Object.assign({}, state, {
        recommendation: [...action.payload?.data],
        metaData: action.payload.meta,
        totalPage: action.payload.meta?.lastPage,
        loader: false,
        totalCount: action.payload.meta?.totalCount,
      });
    }

    case RECOMMENDATION_ERROR: {
      return Object.assign({}, state, {
        loader: false,
      });
    }

    default:
      return state;
  }
};

export default RecommendationReducer;
